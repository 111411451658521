import http, {setUserSession} from "./http-common";
import {eventBus} from "./eventBus";

class ApiService {

    async login(email, password) {
        try {
            const res = await http.post('login', {'email': email, 'password': password});
            if (res.status === 200) {
                setUserSession(res.data.data.access_token, res.data.data.expires_in);
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async getConfigList(searchText, limit, offset) {
        try {
            const res = await http.get(`config`, {params: {searchText: searchText, limit: limit, offset: offset}});
            if (res.status === 200) {
                return res.data.data;
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async getConfig(configId) {
        try {
            const res = await http.get(`config/${configId}`);
            if (res.status === 200) {
                return res.data.data;
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async createConfig(name) {
        try {
            const res = await http.post(`config`, {name: name});
            if (res.status === 200) {
                return res.data.data;
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async deliverConfig(configId, dryRun) {
        try {
            await http.post(`config/${configId}/deliver`, null, {params: {dryRun: dryRun}});
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async deleteConfig(configId) {
        try {
            await http.delete(`config/${configId}`);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async renameConfig(configId, name) {
        try {
            await http.patch(`config/${configId}/rename`,{"name": name});
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async updateSequenceNumber(configId, sequenceNumberId) {
        try {
            await http.patch(`config/${configId}/sequencenumber`,{"sequence_number_id": sequenceNumberId});
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async toggleConfigActive(configId, active) {
        try {
            await http.patch(`config/${configId}/active`,{"active": active});
        } catch(err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async setFilterExpression(configId, filterExpression) {
        try {
            await http.patch(`config/${configId}/filter`,{"filter": filterExpression});
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async setRealtime(configId, realtime) {
        try {
            await http.patch(`config/${configId}/options/realtime`,{"realtime": realtime});
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async setScheduleInterval(configId, interval) {
        try {
            await http.patch(`config/${configId}/options/schedule`,{"schedule": interval});
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async toggleConfigLockable(configId, lockable) {
        try {
            await http.patch(`config/${configId}/options/lockable`,{"lockable": lockable});
        } catch(err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async setConfigNumberOfDeliveries(configId, numberOfDeliveries) {
        try {
            await http.patch(`config/${configId}/options/retry`,{"numberOfDeliveries": numberOfDeliveries});
        } catch(err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async saveProtocol(configId, protocol) {
        try {
            await http.patch(`config/${configId}/protocol`,protocol);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async saveFileFormat(configId, fileFormat) {
        try {
            await http.patch(`config/${configId}/fileformat`,fileFormat);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async saveMapping(configId, mapping) {
        try {
            await http.patch(`config/${configId}/mapping`,mapping);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async getTaskList(configId, limit, offset) {
        try {
            const res = await http.get(`config/${configId}/task`, {params: {limit: limit, offset: offset}});
            if (res.status === 200) {
                return res.data.data;
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async downloadTaskFile(taskId) {
        try {
            const res = await http.get(`task/${taskId}/download`, {responseType:'arraybuffer'});
            if (res.status === 200) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                let fileName = res.headers['content-disposition'];
                if (fileName) {
                    fileName = fileName.split("filename=")[1];
                    link.setAttribute('download', fileName);
                }
                document.body.appendChild(link);
                link.click();
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async resetTask(taskId) {
        try {
            await http.post(`task/${taskId}/reset`);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async getConfigRevisions(configId, limit, offset) {
        try {
            const res = await http.get(`config/${configId}/revision`, {params: {limit: limit, offset: offset}});
            if (res.status === 200) {
                return res.data.data;
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                throw new Error(err.response.data.error.message);
            } else {
                throw err;
            }
        }
    }

    async getDeliveriesBySequenceNumber(sequenceNumberId){
        try{
            const res = await http.get(`sequence/${sequenceNumberId}`);
            return res.data.data;
        }catch (err){
            throw err;
        }
    }

    async editDeliveriesBySequenceNumber(sequenceNumberId,data){
        try{
            const res = await http.patch(`sequence/${sequenceNumberId}`,
                {
                    numberOfDeliveredItems: data.numberOfDeliveredItems,
                    numberOfDeliveries: data.numberOfDeliveries
                });
            if(res.status === 200){
                eventBus.dispatch("successfullyEdited",{
                    numberOfDeliveredItems: data.numberOfDeliveredItems,
                    numberOfDeliveries: data.numberOfDeliveries
                })
            }
        }catch (err){
            throw err;
        }
    }
}

export default new ApiService();